@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

@font-face {
	font-family: "SofiaSans";
	src: url("./assets/fonts/SofiaSans-Regular.ttf");
	src:
		url("./assets/fonts/SofiaSans-Regular.ttf") format("truetype");
	font-style: normal;
	font-weight: 400
}
@font-face {
	font-family: "SofiaSans";
	src: url("./assets/fonts/SofiaSans-Light.ttf");
	src:
		url("./assets/fonts/SofiaSans-Light.ttf") format("truetype");
	font-style: normal;
	font-weight: 300
}
@font-face {
	font-family: "SofiaSans";
	src: url("./assets/fonts/SofiaSans-Bold.ttf");
	src:
		url("./assets/fonts/SofiaSans-Bold.ttf") format("truetype");
	font-style: normal;
	font-weight: 700
}

#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
html, body {
  overscroll-behavior-y: none;
  position: fixed;
  overflow: hidden;
  width: 100vw;
  height: var(--vh);
}

html {
  font-family: "SofiaSans";
}

button {
  background-color: transparent;
  border: none;
  padding: none;
  outline: none;
  cursor: pointer;
  font-family: "SofiaSans";
}

body {
  background: #f0f0f0;
}

.Configurator {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden !important;
}

.Configurator canvas {
  width: 100%;
  height: 100%;
}

.chrome-picker {
  font-family: "SofiaSans" !important;
}

.rangeslider-vertical {
  margin: 10px 0 !important;
  height: 100% !important;
  width: 5px !important;
}
.rangeslider-vertical .rangeslider__fill {
  background-color: #0c0c0c !important;
}

.rangeslider-vertical .rangeslider__handle {
  border: 2px solid #0c0c0c !important;
  width: 28px !important;
  height: 28px !important;
  left: -11px !important;
  border-radius: 50% !important;
}

.rangeslider-vertical .rangeslider__labels {
  visibility: hidden;
}